
//    const env_name = "local"
const env_name = "live"
// const env_name = "demo"

var backurl = ""
if (env_name === "local") {
    backurl = "http://localhost:2053"
}
else {

    backurl = "https://api.nai.systems" // mainet
    // backurl ="https://backend-opengpt.maticz.in" // testnet
    // backurl ="https://backend-nodeai.maticz.in" // testnet

}

export default backurl;